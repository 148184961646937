import React from "react"

import SEO from "../../../components/seo"
import Card from "../../../components/Card"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"

const RysunkiTechnicznePage = () => {
  return (
    <>
      <SEO title="Projects" />
      <Breadcrumb
        crumbs={[
          ["/"],
          ["/", "projects"],
          ["/", "projects", "/tayama"],
          ["/", "projects", "/tayama", "/rysunki-techniczne"],
        ]}
      />
      <div style={{ marginBottom: "100px" }} />
      <div>
        <p>
          Do każdego projektu (tak jak i Rysunki Techniczne dla tayamy) muszę
          robić co jakiś czas (co 2 dni albo inaczej) catch up. Zarówno sam ze
          sobą jak i z innymi zainteresowanymi którzy pracują nad nim.
        </p>
        <p>
          Każdy z projektowa bedzie mial tez albo <strong>Trello board</strong>,
          albo <strong>Jira</strong>, w kazdym razie jakis sposob organizacji
          zadan itd, ktore beda podlinkowane tutaj w systemie.
        </p>
        <p>
          W przypadku rysunków technicznych jest to <strong>Jira board</strong>{" "}
          w projekcie ogólnym <strong>Tayama</strong>
        </p>
        <ul>
          Linki i inne przydatne informacje na zewnatrz
          <li>
            <a href="https://codepride.atlassian.net/secure/RapidBoard.jspa?rapidView=11&projectKey=TAY&selectedIssue=TAY-177&quickFilter=37">
              Jira board (dla zalogowanych)
            </a>
          </li>
          <li>Microsoft Teams - do kontaktu z klientem</li>
          <li>asd</li>
          <li>asd</li>
        </ul>
      </div>

      <div style={{ marginBottom: "300px" }} />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Card>Tayama - rysunki techniczne</Card>
      </div>
    </>
  )
}

export default RysunkiTechnicznePage
