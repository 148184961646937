import React from "react"
import { Link } from "gatsby"

const Breadcrumb = ({ crumbs }) => {
  return (
    <>
      {crumbs.map((crumb, index) => (
        <span key={index}>
          <Link to={crumb.join("")}>{crumb[crumb.length - 1]}</Link> /{" "}
        </span>
      ))}
    </>
  )
}

export default Breadcrumb
