import React from "react"

const Card = props => {
  let backgroundColor = "#fff"
  let color = "#000"
  switch (props.type) {
    case "other":
      backgroundColor = "#fff"
      color = "#000"
      break

    case "primary":
      backgroundColor = "green"
      color = "#fff"
      break

    case "secondary":
      backgroundColor = "purple"
      color = "#fff"
      break

    case "third":
      backgroundColor = "yellow"
      color = "#000"
      break

    case "fourth":
      backgroundColor = "#efefef"
      color = "#000"
      break

    default:
      backgroundColor = "#fff"
      color = "#000"
      break
  }

  return (
    <div
      style={{
        border: "1px solid black",
        padding: "20px",
        margin: "10px",
        backgroundColor,
        color,
      }}
    >
      {props.children}
    </div>
  )
}

export default Card
